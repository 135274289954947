//
// internal routes
export const DASHBOARD = '/'
export const WEBSITE = '/website'
export const TESTIMONIALS = '/website/testimonials'
export const PACKAGES = '/packages'
export const PRODUCTS = '/products'
export const CONNECT = '/connect'
export const PAYMENTS = '/payments'
export const PROGRAMS = '/programs'
export const SCHEDULE = '/schedule'
export const CLASSES = '/classes'
export const STUDENTS = '/students'
export const TAGS = '/students/tags'
export const ANNOUNCEMENTS = '/announcements'
export const INSTRUCTORS = '/instructors'
export const STUDIO = '/studio'
export const ACCOUNT = '/account'
export const PASSWORD_RESET = '/password-reset'
export const SUBSCRIPTION = '/account/subscription'

//
// external routes
export const TRIBU_HELP = 'https://help.tribu.yoga/es'
export const TRIBU_PLANS = 'https://www.tribu.yoga/plans'
export const TRIBU_TERMS = 'https://www.tribu.yoga/terms'
export const TRIBU_PRIVACY = 'https://www.tribu.yoga/privacy'
export const TRIPPING_CATS = 'https://www.trippingcats.com'
export const TRIBU_INSTAGRAM = 'https://www.instagram.com/tribu.yoga.app'
