import { useQuery } from '@tanstack/react-query'
import fnFetch from '@functions/fnFetch'

export function useStudio() {
    return useQuery({
        queryKey: ['studio'],
        queryFn: () => fnFetch('GET', '/identity'),
        staleTime: 30 * 60000, // 30 minutes
    })
}

export function useSubscriptionValidation() {
    return useQuery({
        queryKey: ['subscription', 'validation'],
        queryFn: () => fnFetch('GET', '/subscription/validation'),
        refetchInterval: 45 * 60000, // 45 minutes
        staleTime: 45 * 60000, // 45 minutes
    })
}

export function useInstructorsOptions() {
    return useQuery({
        queryKey: ['shared', 'instructors', 'options'],
        queryFn: () => fnFetch('GET', '/shared/instructors/options'),
        staleTime: 30 * 60000, // 30 minutes
    })
}

export function useClassesOptions() {
    return useQuery({
        queryKey: ['shared', 'classes', 'options'],
        queryFn: () => fnFetch('GET', '/shared/classes/options'),
        staleTime: 30 * 60000, // 30 minutes
    })
}

export function usePackagesOptions() {
    return useQuery({
        queryKey: ['shared', 'packages', 'options'],
        queryFn: () => fnFetch('GET', '/shared/packages/options'),
        staleTime: 30 * 60000, // 30 minutes
    })
}

export function useSchedulesOptions() {
    return useQuery({
        queryKey: ['shared', 'schedules', 'options'],
        queryFn: () => fnFetch('GET', '/shared/schedules/options'),
        staleTime: 30 * 60000, // 30 minutes
    })
}

export function useProgramsOptions() {
    return useQuery({
        queryKey: ['shared', 'programs', 'options'],
        queryFn: () => fnFetch('GET', '/shared/programs/options'),
        staleTime: 30 * 60000, // 30 minutes
    })
}
