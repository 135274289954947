import React from 'react'
import Gleap from 'gleap'

// import global styles
import '@assets/fonts/fonts.scss'
import '@assets/css/main.scss'

// configure layout
import Layout from '@app/layout'

// props provide same data to Layout as Page element will get
// including location, data, etc - you don't need to pass it
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = () => {
    window.addEventListener('load', () => {
        //
        // Register a Service Worker
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/sw.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope)
                })
                .catch((error) => {
                    console.log('Service Worker registration failed:', error)
                })
        }

        //
        // Init Gleap
        // Check docs on: https://docs.gleap.io/javascript
        Gleap.setLanguage('es')
        Gleap.initialize(process.env.GATSBY_GLEAP_KEY)
        Gleap.setEnvironment(process.env.GATSBY_GLEAP_ENVIRONMENT)
        Gleap.disableConsoleLogOverwrite()
        Gleap.setTags(['App'])
        Gleap.showFeedbackButton(false)
    })
}

//
// Add custom service worker
export const registerServiceWorker = () => true
