import React, { createContext, useContext, useState, useEffect } from 'react'

// Create the context with a default value of null
const PaddleContext = createContext(undefined)

// Hook to use it all over the app
export const usePaddleCtx = () => {
    return useContext(PaddleContext)
}

export const PaddleContextProvider = ({ children }) => {
    const [paddleBilling, setPaddleBilling] = useState(null)
    const [paddleClassic, setPaddleClassic] = useState(null)

    useEffect(() => {
        const billingScript = document.createElement('script')

        billingScript.src = 'https://cdn.paddle.com/paddle/v2/paddle.js'
        billingScript.async = true
        billingScript.onload = () => {
            //
            // Load Paddle Billing
            window.PaddleBilling = window.Paddle
            delete window.Paddle

            if (process.env.GATSBY_PADDLE_ENV === 'sandbox') {
                window.PaddleBilling.Environment.set('sandbox')
            }

            window.PaddleBilling.Initialize({
                token: process.env.GATSBY_PADDLE_BILLING_CLIENT_SIDE_TOKEN,
                checkout: {
                    settings: {
                        locale: 'es',
                        displayMode: 'inline',
                        frameTarget: 'paddle-checkout',
                        frameInitialHeight: 450,
                        frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;',
                        allowLogout: false,
                    },
                },
            })

            setPaddleBilling(window.PaddleBilling)

            //
            // Load Paddle Billing
            const classicScript = document.createElement('script')

            classicScript.src = 'https://cdn.paddle.com/paddle/paddle.js'
            classicScript.async = true
            classicScript.onload = () => {
                window.PaddleClassic = window.Paddle
                delete window.Paddle

                if (process.env.GATSBY_PADDLE_ENV === 'sandbox') {
                    window.PaddleClassic.Environment.set('sandbox')
                }

                window.PaddleClassic.Setup({
                    vendor: parseInt(process.env.GATSBY_PADDLE_VENDOR_ID),
                    debug: process.env.GATSBY_PADDLE_ENV === 'sandbox',
                })

                setPaddleClassic(window.PaddleClassic)
            }

            document.body.appendChild(classicScript)
        }
        document.body.appendChild(billingScript)
    }, [])

    return (
        <PaddleContext.Provider value={{ PaddleBilling: paddleBilling, PaddleClassic: paddleClassic }}>
            {children}
        </PaddleContext.Provider>
    )
}
