import React, { useEffect, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

// utils
import { IconTimes } from '@constants/constIcons'
import useReactQueryClient from '@hooks/useReactQueryClient'

// context
import { PaddleContextProvider } from '@app/context/paddle'
import { StudioContextProvider } from '@app/context/studio'

// styles
import * as css from './index.module.scss'

export default function Layout({ children, location }) {
    const [notification, setNotification] = useState(null)
    const queryClient = useReactQueryClient(setNotification)

    useEffect(() => {
        setTimeout(() => {
            window.scroll({ top: -1, left: 0, behavior: 'smooth' })
        }, 10)
    }, [location])

    useEffect(() => {
        if (notification !== null) {
            const id = setTimeout(() => setNotification(null), 7000)
            return () => clearTimeout(id)
        }
    }, [notification, setNotification])

    return (
        <QueryClientProvider client={queryClient}>
            <PaddleContextProvider>
                <StudioContextProvider>{children}</StudioContextProvider>
            </PaddleContextProvider>
            {notification && (
                <div className={css.notification + ' ' + css[notification.mode]}>
                    <div className={css.title}>{notification.title}</div>
                    <div className={css.message}>{notification.message}</div>
                    <div className={css.close} onClick={() => setNotification(null)} role='button' aria-hidden>
                        <IconTimes />
                    </div>
                </div>
            )}
        </QueryClientProvider>
    )
}
